.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.box:hover .image {
  opacity: 0.3;
}

.box:hover .middle {
  opacity: 1;
}

.box:hover .text {
  /* background-color: #d3a4ff; */
  color: black;
  font-size: 40px;
  font-family: "Brush Script MT";
  /* padding: 16px 32px; */
}

.shadow {
  box-shadow: 0 10px 12px rgba(50, 50, 93, 0.11), 0 6px 8px rgba(0, 0, 0, 0.08);
}

.hoverHand2 {
  cursor: grab;
  box-shadow: 0 10px 12px rgba(50, 50, 93, 0.11), 0 6px 8px rgba(0, 0, 0, 0.08);
}

.roundCorners {
  border-radius: 12px;
}
.fullyRound {
  border-radius: 360px;
}

.round {
  border-radius: 50%;
}

.zoom {
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5);
}

.hoverHand {
  cursor: grab;
  /* box-shadow: 0 10px 12px rgba(50, 50, 93, 0.11), 0 6px 8px rgba(0, 0, 0, 0.08); */
}

@media screen and (max-width: 1650px) {
  .logo {
    max-width: 350px;
    max-height: 300px;
  }
}